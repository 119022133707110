div.react-calendar {
  @apply rounded shadow overflow-hidden {}
  @apply border-0 #{!important} {}
  font-family: inherit;

  abbr[title] {
    @apply text-xs font-bold uppercase no-underline {} }

  button {
    @apply transition transition-colors {}

    &:enabled:hover {
      @apply bg-gray-200 text-gray-600 {} } }

  &__navigation {
    @apply mb-0 border-b-2 border-gray-200 {}

    button {
      &[disabled] {
        @apply cursor-default pointer-events-none bg-transparent text-transparent {} } } } }

button.react-calendar {
  &__tile {
    &[disabled] {
      @apply cursor-default pointer-events-none bg-transparent text-gray-400 {} }

    &--now {
      @apply bg-primary/10 {} }

    &--hasActive {
      @apply bg-primary text-white {} } }

  &__month-view__days__day--neighboringMonth {
    @apply text-gray-400 {} }

  &__tile--active {
    @apply bg-primary {} } }

.disabled-cell-coco {
  opacity: 0 !important; }

.not-disabled-cell-coco {
  opacity: 1 !important; }

.fc-daygrid-day-number {
  text-align: center !important;
  width: 100% !important;
  max-width: 100% !important;
  min-width: 100% !important;
  height: auto !important; }

.fc-daygrid-day-top {
  width: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important;
  display: flex !important;
  justify-content: space-between; }

.today-custom {
  @apply text-white font-bold rounded-full flex items-center bg-primary justify-center w-6 h-6 {} }

.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 310px;
  height: 2em;
  white-space: nowrap; }

.cut-comment-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 60px; }

.cut-options-text {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 210px;
  height: 2em;
  white-space: nowrap; }

.top-zIndex {
  position: relative !important;
  z-index: 2147483647 !important; }

.kanban-container {
  display: flex !important;
  width: 100% !important;
  overflow-x: auto !important;
  overflow-y: hidden !important;
  scroll-behavior: smooth !important; }
