.datatable {
  @apply overflow-visible #{!important} {} }

.rdt_TableRow {
  @apply w-full bg-white border-none transition transition-colors hover:bg-gray-200 #{!important} {}

  &:hover .rdt_TableCell {
    @apply border-white #{!important} {} } }

.rdt_TableCell {
  @apply p-3 border-b border-gray-200 border-e transition transition-colors text-gray-600 last:border-e-0 #{!important} {}

  &[id*='id-cell'] {
    @apply text-gray-400 w-full #{!important} {} }

  &[id*='id-startDate'],
  &[id*='id-endDate'] {
    @apply whitespace-nowrap {} }

  &[id*='id-labels'] {
    @apply min-w-80 {} } }

.rdt_TableCol {
  @apply ps-3 pb-3 pt-6 bg-gray-200 sticky top-0 z-10 whitespace-nowrap text-xs font-bold uppercase text-gray-600 #{!important} {} }

.rdt_TableCol_Sortable {
  @apply text-gray-600 transition-colors hover:text-primary #{!important} {}

  .__rdt_custom_sort_icon__.__rdt_custom_sort_icon__ svg {
    @apply w-6 h-6 #{!important} {} } }

.rdt_TableHead {
  @apply uppercase text-start {} }

.rdt_TableHeadRow {
  @apply border-none #{!important} {} }

//.InovuaReactDataGrid__column-layout
//  @apply grid overflow-auto #{!important}

.inovua-react-virtual-list__view-container {
  overflow-x: visible !important;
  overflow-y: auto !important; }

.inovua-react-toolkit-menu.inovua-react-toolkit-menu--theme-default-light .inovua-react-toolkit-menu__row--over .inovua-react-toolkit-menu__cell {
  background-color: rgba(0,178,169,.15) !important; }

.InovuaReactDataGrid__column-header__menu-tool:hover svg {
  opacity: 0.4 !important;
  fill: #00B2A9 !important; }

.inovua-react-toolkit-checkbox__icon-wrapper svg {
  fill: #00B2A9 !important; }

.inovua-react-toolkit-combo-box__list__item--selected {
  background: none !important;
  color: #00B2A9 !important; }

.inovua-react-toolkit-combo-box__list--theme-default-light {
  background: #000 !important;
  border-radius: 1px;
  border: none;
  box-shadow: 0 0 4px 0 rgba(0,0,0,.1); }

.inovua-react-toolkit-combo-box__list__item {
  &:hover {
    background-color: rgba(0,178,169, 0.3) !important; } }

.inovua-react-toolkit-combo-box__list--theme-default-light {
  @apply rounded text-sm shadow-multi text-gray-600 #{!important} {}

  &:hover {
    @apply border-primary shadow-multi rounded text-sm text-gray-600 active:bg-primary #{!important} {} } }

.mx-grid tr.selected {
  background-color: #FFFFFF !important;
  border: 5px solid #000 !important; }

.border-* {
  color: #00B2A9 !important; }

.inovua-react-toolkit-radio-button__icon-wrapper {
  fill: #00B2A9 !important;
  stroke: #e1e1e1; }

.inovua-react-toolkit-checkbox__icon-wrapper svg {
  background: #ffffff !important;
  stroke: #eaeaea; }

.inovua-react-toolkit-menu.inovua-react-toolkit-menu--theme-default-light .inovua-react-toolkit-menu__row--focused .inovua-react-toolkit-menu__cell:before {
  border-color: #00B2A9 !important; }

.InovuaReactDataGrid.test.InovuaReactDataGrid--theme-default-light {
  width: 100% !important;
  background: white !important;
  overflow-x: hidden !important; }

.inovua-react-toolkit-menu {
  @apply z-50 px-3 py-2 -me-12 h-56	text-xs bg-white cursor-default shadow-multi rounded-ts-none rounded-2xl #{!important} {}

  &::selection {
    @apply bg-primary #{!important} {} } }

.inovua-react-toolkit-arrow-scroller.inovua-react-toolkit-arrow-scroller--theme-default-light .inovua-react-toolkit-arrow-scroller__arrow:hover:before {
  background: rgba(234,234,234,.25) !important; }

.InovuaReactDataGrid--theme-default-light.InovuaReactDataGrid {
  @apply border-t-0 border-s-0 border-e-0 #{!important} {}

  .react-select {
    &__control {
      @apply overflow-auto {}
      max-height: 2.25rem !important; }

    &__single-value ~ .react-select__input-container {
      @apply w-0 {} } }

  .react-date-picker {
    @apply bg-white border-2 border-primary transition rounded shadow-none #{!important} {}
    min-height: 2.25rem !important;

    &__calendar {
      @apply mt-4 {} }

    &__button {
      @apply -mt-2 -mb-2 {} } }

  .InovuaReactDataGrid__cell__editor {
    @apply left-1 right-1 top-1 bottom-1 h-9 #{!important} {}
    width: calc(100% - 8px) !important; }

  .inovua-react-toolkit-text-input.inovua-react-toolkit-text-input--theme-default-light,
  .inovua-react-toolkit-numeric-input.inovua-react-toolkit-numeric-input--theme-default-light {
    @apply px-2 border-2 border-gray-200 transition rounded shadow-none text-sm text-gray-600 #{!important} {}
    direction: unset;
    min-height: 2rem !important;

    &::selection {
     @apply px-2 border-2 border-primary transition rounded shadow-none text-sm text-gray-600 #{!important} {}
     min-height: 2rem !important; }

    &:hover {
     @apply px-2 border-2 border-primary transition rounded shadow-none text-sm text-gray-600 #{!important} {}
     min-height: 2rem !important; } } }

.inovua-react-toolkit-text-input.inovua-react-toolkit-text-input--theme-default-light,
.inovua-react-toolkit-numeric-input.inovua-react-toolkit-numeric-input--theme-default-light {
  @apply transition rounded shadow-none text-sm text-gray-600 #{!important} {}
  &:hover {
    @apply border-primary transition rounded shadow-none text-sm text-gray-600 active:bg-primary #{!important} {} } }

.inovua-react-toolkit-text-input.inovua-react-toolkit-text-input--theme-default-light.inovua-react-toolkit-text-input--focused {
  @apply border-primary flex items-center -mt-1 transition rounded shadow-none text-sm text-gray-600 active:bg-primary #{!important} {} }

.InovuaReactDataGrid__column-header__filter-settings {
  @apply text-gray-600 #{!important} {}
  width: 12px !important; }

.InovuaReactDataGrid__column-header__filter-settings svg {
  @apply hover:text-primary #{!important} {}
  opacity: 0.5 !important;
  &:hover {
    fill: #00B2A9 !important; } }

.inovua-react-toolkit-combo-box {
  @apply border-gray-200 border-2 transition rounded shadow-none text-sm text-gray-300 hover:border-primary #{!important} {}
  min-height: 2rem !important;
  &:focus {
   @apply border-primary transition rounded shadow-none text-sm text-gray-300 active:bg-primary #{!important} {} } }

.inovua-react-toolkit-radio-button__icon-wrapper svg {
  color: #d2d2d2 !important;
  fill: #00B2A9 !important;
  stroke: #e1e2e3 !important; }

.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__column-header__filter-wrapper {
  min-height: 41.2px !important; }

.inovua-react-toolkit-combo-box__toggle-icon:before {
  background: transparent !important; }

.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid {
  &__header {
    @apply bg-gray-200 border-b #{!important} {} }

  &__column-header {
    @apply whitespace-nowrap text-xs uppercase text-gray-600 #{!important} {}

    &--sortable {
      @apply text-gray-600 transition-colors hover:text-primary #{!important} {}

      svg {
        @apply opacity-0 {} }

      &:hover svg {
        @apply opacity-40 text-primary {} }

      &.InovuaReactDataGrid__column-header--sort-asc svg,
      &.InovuaReactDataGrid__column-header--sort-desc svg {
        @apply opacity-100 {} } } }

  &__column-header__content {
    @apply flex items-center {} }

  &__column-header,
  &__column-header__resize-wrapper,
  &__cell {
    @apply border-gray-200 #{!important} {} }

  &__cell {
    @apply p-1 text-sm text-gray-600 #{!important} {}

    .checkbox__label:before {
      @apply mx-auto {} }

    .InovuaReactDataGrid__cell__content {
      @apply px-2 relative {} }

    &.-editable .InovuaReactDataGrid__cell__content {
      @apply flex items-center border-2 border-transparent border-dashed rounded cursor-pointer {}
      @apply hover:bg-white hover:border-gray-300 {}
      min-height: 2rem !important; }

    &.-editable.-end-align .InovuaReactDataGrid__cell__content {
      @apply justify-end {} }

    &.-editable.-status .InovuaReactDataGrid__cell__content {
      @apply px-0 border-0 relative {}

      &:hover:after {
        content: '';
        @apply absolute w-full h-9 left-0 top-0 z-10 border-2 border-dashed border-gray-300 rounded pointer-events-none {}
        max-height: 2rem !important; } }

    &.-editable.-text .InovuaReactDataGrid__cell__content {
      @apply flex items-center #{!important} {} }

    &.-editable.-labels .InovuaReactDataGrid__cell__content {
      @apply block pt-1 {} }

    &.-settings .InovuaReactDataGrid__cell__content {
      @apply px-0 {} }

    &--in-edit.-editable .InovuaReactDataGrid__cell__content {
      @apply px-0 border-0 {} } }

  &____virtual-list InovuaReactDataGrid__row--first .InovuaReactDataGrid__cell {
    @apply border-t #{!important} {} }

  &__row-hover-target {
    @apply hover:bg-gray-200 #{!important} {} }

  &__row-hover-target:hover .InovuaReactDataGrid__cell,
  &__row--active .InovuaReactDataGrid__cell {
    border-inline-start-color: white !important; } }

.new-row__index-cell {
  flex: 0 0 52px; }

.grid-view-description-input .ProseMirror {
  -webkit-user-select: text;
  user-select: text; }

.cell {
  text-align: center !important; }

.add-custom-field svg {
  opacity: 1 !important; }
