.ProseMirror {
  @apply focus-within:outline-none {} }

.mention {
  @apply py-0.5 px-1 rounded-sm bg-gray-200 {}

  &.-current-user {
    @apply bg-primary text-white {} } }


.mentions {
  @apply bg-white min-h-12 #{!important} {}

  &__highlighter {
    @apply px-2.5 py-3 {}
    @apply border-none #{!important} {}

    strong {
      @apply bg-gray-200 py-0.5 rounded-sm {}
      @apply border-none #{!important} {} } }

  &__input {
    @apply border-2 min-h-12 w-44 border-gray-300 text-gray-600 w-full px-2 py-2.5 rounded leading-6 transition transition-colors focus-within:border-primary focus:outline-none {} }

  &__suggestions {
    @apply z-30 text-sm bg-white shadow rounded-ts-none rounded-lg py-1 overflow-hidden min-w-48 mt-6 #{!important} {}

    &__list {
      @apply overflow-auto max-h-48 {} }

    &__item {
      @apply px-4 py-1 font-normal hover:bg-primary/10 {}

      &--focused {
        @apply bg-primary/10 {} }
      &__highlight {
        @apply font-bold text-gray-800 #{!important} {} } } }

  &.-rows {
    &.mentions--multiLine {
      @apply rounded-lg {} }

    .mentions__input {
      @apply h-auto bottom-auto overflow-auto #{!important} {} }

    .mentions__highlighter {
      height: calc(calc(var(--rows) * 24px) + 24px); } } }

