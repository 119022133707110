.multi-select-container {
  @apply border-gray-300 #{!important} {}
  width: auto !important; }

.msl-option-active {
  background: #f1f2f3 !important;
  color: #00B2A9 !important; }

.searchWrapper {
  @apply border-white -mx-2 -my-1 hover:border-dashed focus:whitespace-normal transition transition-colors hover:border-gray-300 focus:border-primary #{!important} {}
  @apply hover:border-gray-300 hover:bg-white {}
  min-height: 2.25rem !important;
  &--is-focused {
    @apply border-primary border-solid bg-white hover:border-primary {} } }

.msl-active {
  box-shadow: none !important; }

.msl-input:before {
  --tw-text-opacity: 1;
  font-size: small !important;
  color: rgba(197, 212, 226, var(--tw-text-opacity)) !important; }

.msl-arrow-btn {
  @apply hover:bg-white #{!important} {} }

.msl-active-up~.msl-options {
  @apply m-0 py-2 -mx-2 -my-1 shadow-multi border-0 overflow-hidden bg-white min-w-48 rounded-ts-none rounded-2xl text-sm #{!important} {}
 }  //box-shadow: none !important

.optionContainer {
  @apply border-2 border-transparent bg-transparent border-dashed transition transition-colors rounded shadow-none cursor-pointer text-sm flex {} }

.optionContainer li:hover,
.optionContainer .highlight {
  background: #f1f2f3 !important;
  color: #00B2A9 !important; }

.chip {
  @apply bg-gray-200 text-gray-800 #{!important} {} }

//.checkbox
//  @apply me-2 #{!important}
//  opacity: 1 !important
//  position: relative !important
//  color: white !important
////accent-color: #00B2A9 !important

.optionListContainer {
  @apply m-0 py-2 shadow-multi border-0 overflow-hidden bg-white min-w-48 rounded-ts-none rounded-2xl #{!important} {}
  border: none !important; }

.optionContainer {
  border-color: transparent !important; }

.cut-formula-text {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 420px;
  height: 2em;
  white-space: nowrap; }

.disabled-container {
  pointer-events: none !important;
  opacity: 0.7 !important; }
