.tippy-box[data-theme~='custom-primary'] {
  @apply mt-6 text-xs bg-gray-300 font-bold text-gray-800 p-1.5 rounded-te-lg rounded-be-lg rounded-bs-lg #{!important} {} }

.tippy-box[data-theme~='custom-bottom'] {
  @apply text-xs bg-gray-300 font-bold text-gray-800 p-1.5 rounded-te-lg rounded-be-lg rounded-bs-lg #{!important} {} }

.tippy-box[data-theme~='custom-top'] {
  @apply -mb-3 text-xs bg-gray-300 font-bold text-gray-800 p-1.5 rounded-te-lg rounded-be-lg rounded-bs-lg #{!important} {} }

.tippy-box[data-theme~='calendar-tooltip'] {
  @apply -mb-3 w-full rounded shadow text-xs bg-white font-bold text-gray-800 p-1.5 rounded-te-lg rounded-be-lg rounded-bs-lg #{!important} {} }

.tippy-box[data-theme~='custom-warning'] {
  @apply -mt-3 text-xs bg-danger font-bold text-white p-1.5 rounded-te-lg rounded-be-lg rounded-bs-lg #{!important} {} }
