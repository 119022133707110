.linked-field-text {
   text-overflow: ellipsis;
   overflow: hidden;
   width: 130px;
   white-space: nowrap; }

.cut-text-dots {
   display: -webkit-box;
   -webkit-line-clamp: 1;
   -webkit-box-orient: vertical;
   overflow: hidden;
   max-height: 30px; }

.rotate-icon {
   transform: rotate(90deg) !important; }

.menuCard {
   @apply flex flex-col gap-1 z-30 px-2 py-2 text-sm bg-white cursor-default shadow-multi w-44 rounded-ts-none rounded-2xl #{!important} {}
   position: absolute !important; }

.truncate-comment {
   p {
      @apply truncate {} } }

@page {
  size: auto; }

.container-relative {
  position: relative !important; }

