@font-face {
  font-family: 'Lato';
  src: url('#{$font-path}/Lato-Light.woff2') format('woff2'),
  url('#{$font-path}/Lato-Light.woff') format('woff') {}
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Lato';
  src: url('#{$font-path}/Lato-Regular.woff2') format('woff2'),
  url('#{$font-path}/Lato-Regular.woff') format('woff') {}
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Lato';
  src: url('#{$font-path}/Lato-Bold.woff2') format('woff2'),
  url('#{$font-path}/Lato-Bold.woff') format('woff') {}
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Almarai';
  src: url('#{$font-path}/Almarai-Light.woff2') format('woff2'),
  url('#{$font-path}/Almarai-Light.woff') format('woff') {}
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Almarai';
  src: url('#{$font-path}/Almarai-Regular.woff2') format('woff2'),
  url('#{$font-path}/Almarai-Regular.woff') format('woff') {}
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Almarai';
  src: url('#{$font-path}/Almarai-Bold.woff2') format('woff2'),
  url('#{$font-path}/Almarai-Bold.woff') format('woff') {}
  font-weight: 700;
  font-style: normal;
  font-display: swap; }
