.ck-editor__editable_inline {
    //min-height: calc(100%)
    //height: calc(100%)
    margin: 100px auto 100px auto!important;
    width: 720px;
    overflow: hidden;
    max-width: 100%;
    align-self: center;
    // -webkit-box-shadow: 0px 2px 18px 0px rgba(34, 60, 80, 0.2)
    // -moz-box-shadow: 0px 2px 18px 0px rgba(34, 60, 80, 0.2)
    // box-shadow: 0px 2px 18px 0px rgba(34, 60, 80, 0.2)
    box-shadow: none !important;
    //padding: 64px 96px 96px 96px !important
    //position: absolute
    word-wrap: break-word !important;
    //overflow-x: hidden
    border: 1px solid transparent !important; }
p a {
    color: #00B2A9 !important;
    text-decoration: underline !important; }

blockquote {
    overflow: hidden !important;
    padding-right: 1.5em !important;
    padding-left: 1.5em !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    font-style: italic !important;
    border-inline-start: 5px solid #c5d4e2 !important;
    border-radius: 3px !important;

    p {
        margin-top: 0px !important; } }

.ck-editor__editable_inline {
    p, div, blockquote {
        margin-top: 0.8em; }
    h2 {
        margin-top: 1.4em;
        font-size: 1.75rem;
        line-height: 2.25rem;
        font-weight: bold;
        @apply text-gray-700 #{!important} {} }
    h3 {
        margin-top: 1.2em;
        font-size: 1.5rem;
        line-height: 2rem; }
    h4 {
        margin-top: 1em;
        font-size: 1.25rem;
        line-height: 1.625rem; }
    hr {
        margin-top: 0.8em !important;
        height: 3px !important;
        border-width: 2px !important;
        border-radius: 5px !important; } }

.ck-view {
    p, div, blockquote, table {
        margin-top: 0.8em; }
    h2 {
        margin-top: 1.4em;
        font-size: 1.75rem;
        line-height: 2.25rem;
        font-weight: bold;
        @apply text-gray-700 #{!important} {} }
    h3 {
        margin-top: 1.2em;
        font-size: 1.5rem;
        line-height: 2rem; }
    h4 {
        margin-top: 1em;
        font-size: 1.25rem;
        line-height: 1.625rem; }
    hr {
        margin-top: 0.8em !important;
        height: 3px !important;
        border-width: 2px !important;
        border-radius: 5px !important; } }

@media (max-width: 1000px) {
    .ck-editor__editable_inline {
        min-height: calc(100% - 200px);
        height: calc(100% - 180px);
        width: calc(100% - 2px) !important;
        padding: 32px !important;
        position: absolute; } }

.ck-focused {
    border: 1px solid transparent !important;
    box-shadow: none !important;
    // -webkit-box-shadow: 0px 2px 18px 0px rgba(34, 60, 80, 0.2) !important
    // -moz-box-shadow: 0px 2px 18px 0px rgba(34, 60, 80, 0.2) !important
 }    // box-shadow: 0px 2px 18px 0px rgba(34, 60, 80, 0.2) !important

.ck-toolbar {
    background-color: white !important;
    border: none !important;
    border-bottom: 3px solid #eeeeee !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    border-radius: 0 !important; }

.ck.ck-toolbar.ck-toolbar_grouping.ck-reset_all.ck-rounded-corners {
    position: absolute !important;
    background: white !important;
    z-index: 10 !important;
    width: calc(100% - 180px) !important;
    @media screen and (max-width: 1000px) {
        width: calc(100% - 130px) !important; } }

.ck.ck-toolbar-dropdown .ck-toolbar {
    @media screen and (max-width: 1000px) {
        min-width: fit-content !important; } }

.ck.ck-reset_all.ck-toolbar {
    color: #33647E !important; }

.ck.ck-reset_all * {
    color: #33647E !important; }

.ck.ck-editor__editable_inline > :first-child {
    margin-bottom: 16px; }

.ck.ck-tooltip .ck-tooltip__text {
    color: #fff !important; }

.ck.ck-find-and-replace-form fieldset.ck-find-and-replace-form__find > .ck-button-find {
    background-color: #33647E !important; }

.ck.ck-find-and-replace-form fieldset.ck-find-and-replace-form__find > .ck-button-find .ck-button__label {
    color: #fff !important; }

.documentation-table-default tr td {
    min-width: 2em !important;
    padding: 0.4em;
    border: 1px solid #bfbfbf; }

.documentation-figure-center {
    margin: 0 auto; }

.documentation-figure-center table {
    margin: 0 auto; }

.text-huge {
    font-size: 1.8em !important; }

.text-big {
    font-size: 1.4em !important; }

.text-small {
    font-size: .85em !important; }

.text-tiny {
    font-size: .7em !important; }

.image {
    display: table !important;
    clear: both !important;
    text-align: center !important;
    margin: .9em auto !important;
    min-width: 50px !important; }

.image-style-side {
    float: right !important;
    margin-left: var(--ck-image-style-spacing) !important;
    max-width: 50% !important; }

.image-inline {
    position: relative !important; }

h2 {
    font-size: 1.3rem; }

h3 {
    font-size: 1.18rem; }

h4 {
    font-size: 1.14rem; }

.ck.ck-content .todo-list {
    list-style-type: none; }

.ck.ck-content ul,
.ck.ck-content ul li {
    list-style-type: inherit; }

.ck.ck-content ul {
    padding-inline-start: 20px; }

.ck.ck-content ol {
    list-style-type: decimal;
    padding-inline-start: 20px; }

.ck-view ul {
    list-style-type: inherit;
    padding-inline-start: 20px; }

.ck-view ol {
    list-style-type: decimal;
    padding-inline-start: 20px; }

.ck-view li a {
    color: #00B2A9 !important;
    text-decoration: underline !important; }

.ck.ck-content li a {
    color: #00B2A9 !important;
    text-decoration: underline !important; }

.ck.ck-list__item .ck-button.ck-on {
    background: #00B2A9 !important;
    color: white !important; }

.ck.ck-editor__editable pre {
    position: relative; }

pre {
    padding: 1em;
    color: #353535;
    background: hsla(0,0%,78%,.3);
    border: 1px solid #c4c4c4;
    border-radius: 2px;
    text-align: left;
    direction: ltr;
    tab-size: 4;
    white-space: pre-wrap;
    font-style: normal;
    min-width: 200px; }

pre[data-language]:after {
    content: attr(data-language);
    position: absolute;
    top: -1px;
    right: 10px;
    background: var(--ck-color-code-block-label-background);
    font-size: 10px;
    font-family: var(--ck-font-face);
    line-height: 16px;
    padding: var(--ck-spacing-tiny) var(--ck-spacing-medium);
    color: #fff;
    white-space: nowrap; }

pre code {
    background-color: transparent; }

code {
    background-color: hsla(0,0%,78%,.3);
    padding: .15em;
    border-radius: 2px;
    font-size: 0.9em; }

.image_resized {
    display: inline;
    margin-inline-end: 0.2em; }

.media {
    clear: both;
    mergin: 0.9em 0;
    display: block;
    min-width: 15em; }
