.calendar-width .fc-view-harness {
  min-width: 800px;
  max-width: 100%;
  width: 100%; }

.fc-header-toolbar {
  @apply h-0 mb-0 #{!important} {} }

.fc-day-today {
  .fc-daygrid-day-number {
    @apply text-primary font-extrabold rounded-full flex items-center justify-center w-6 h-6 {} } }

.fc-daygrid-day-top {
  @apply flex-row w-full flex justify-between #{!important} {} }

.fc-popover-header {
  @apply bg-white #{!important} {} }

.fc-scrollgrid-section-body {
  @apply bg-white {} }

.fc-scrollgrid-section.fc-scrollgrid-section-body.fc-scrollgrid-section-liquid {
  @apply h-full #{!important} {} }

.fc-daygrid-body.fc-daygrid-body-unbalanced {
  tbody {
    @apply flex flex-col h-full {} } }

.fc-scroller-harness.fc-scroller-harness-liquid {
  @apply overflow-auto static #{!important} {} }

.fc.fc-scrollgrid-liquid {
  @apply h-auto #{!important} {} }

tr.fc-scrollgrid-section.fc-scrollgrid-section-header {
  @apply h-auto #{!important} {}

  td {
    @apply h-auto #{!important} {} } }

.fc-scroller.fc-scroller-liquid-absolute {
  @apply static #{!important} {} }

.fc-timeline-event,
.fc-event-title {
  @apply p-0 #{!important} {} }

.fc-h-event {
  @apply border-none rounded-sm #{!important} {} }

.fc-event-main {
  @apply p-1 h-8 z-0 leading-6 text-sm #{!important} {} }

.calendar {
  width: 100% !important;
  @apply text-sm {}

  td:last-of-type() {
    @apply border-e-0 #{!important} {} }

  tr:last-of-type() {
    td {
      @apply border-b-0 #{!important} {} } }

  table, th {
    @apply border-0 #{!important} {} }

  tr {
    @apply h-auto flex-1 flex {} }

  td, th {
    @apply flex-1 min-w-0 {} }

  &__day-header {
    @apply border-none p-2 text-xs font-bold uppercase #{!important} {}
    text-align: start #{!important}; }

  &__day-cell:not(.fc-popover) {
    @apply p-2 pt-1 bg-white min-h-28 border-gray-200 border-s border-l-0 cursor-pointer hover:bg-tertiary-light/5 transition-colors #{!important} {} }

  &__event {
    @apply mb-1 pointer-events-auto bg-white transition-colors focus:outline-none focus:border-primary-dark #{!important} {} }

  &__now {
    @apply bg-warning {} }

  &__date-clicked {
    .fc-highlight {
      @apply bg-transparent #{!important} {} } } }

.fc-timeline-header > table > tbody > tr:nth-child(1) {
  @apply items-center {}
  height: 64px !important; }

.fc-timeline-header > table > tbody > tr:nth-child(2) {
  display: none !important; }

.fc-timeline-slot-frame {
  height: 64px !important; }
