.stations-widget {
  max-height: 100vh !important;
  min-height: 100vh !important;
  height: 100vh !important;
  overflow: hidden !important; }

.tasks-widget {
  max-height: 100vh !important;
  min-height: 100vh !important;
  height: 100vh !important;
  overflow: hidden !important; }

.long-text {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 500px;
  white-space: nowrap; }

.station-small-card {
  height: 84px !important;
  max-height: 84px !important;
  min-height: 84px !important;
  overflow: hidden !important; }

.station-small-number {
  height: 84px !important;
  max-height: 84px !important;
  min-height: 84px !important; }

.background-image {
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important; }
