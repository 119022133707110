@tailwind base {}
@tailwind components {}
@tailwind utilities {}

@import "base/variables";
@import "base/base";

@import "shared/fonts";
@import "shared/forms";
@import "shared/utilities";

@import "views/login";

@import "components/calendar";
@import "components/calendar-view";
@import "components/context-menu";
@import "components/datepicker";
@import "components/file";
@import "components/grid-view";
@import "components/mentions";
@import "components/panel";
@import "components/prosemirror";
@import "components/reactour";
@import "components/select";
@import "components/timeline";
@import "components/toastify";
@import "components/ckeditor";
@import "components/tooltip";
@import "components/new-kanban";
@import "components/react-grid-layout";
@import "components/custom-fields";
@import "components/dashboard";
@import "components/small-select";
@import "src/styles/components/radio";
@import "components/notifications";
@import "components/common";
@import "components/smsValidation";
