.Toastify {
  &__toast {
    @apply min-h-0 shadow ps-6 pe-4 py-4 rounded {}
    font-family: inherit;

    &--default {
      @apply text-gray-600 {} }

    &--info {
      @apply bg-primary text-white {} }

    &--success {
      @apply bg-success text-white {} }

    &--warning {
      @apply bg-warning text-white {} }

    &--error {
      @apply bg-danger text-white {} } }

  &__toast-body {
    @apply p-0 {} }

  &__close-button {
    @apply flex items-center justify-center {}
    color: inherit;

    svg {
      @apply w-auto h-6 {} } } }
