.custom-styles {
  --ReactInputVerificationCode-itemWidth: 2.5rem;
  --ReactInputVerificationCode-itemHeight: 3.5rem; }


.custom-styles .ReactInputVerificationCode__item {
  @apply text-gray-600 #{!important} {}
  position: relative;
  font-weight: 500; }


.custom-styles .ReactInputVerificationCode__item,
.custom-styles .ReactInputVerificationCode__item.is-active {
  box-shadow: none;
  border: 1px solid #eaeaea; }


.custom-styles .ReactInputVerificationCode__item:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 0px;
  background-color: #eaeaea;
  transition: background-color 0.2s ease-out; }


.custom-styles .ReactInputVerificationCode__item.is-active:after {
  background-color: #046cde;
  border: 1px solid #00B2A9; }

