div[class*=-container] {
  @apply focus:border-primary {} }

div.react-small-center-select {
  $this: &;

  &__control {
    @apply border-2 border-transparent bg-transparent border-dashed min-h-12 transition transition-colors rounded shadow-none cursor-pointer text-sm flex {}
    @apply hover:border-gray-300 hover:bg-white {}

    &--is-focused {
      @apply border-primary border-solid bg-white hover:border-primary {}

      #{$this}__clear-indicator {
        @apply visible #{!important} {} } } }

  &__dropdown-indicator {
    @apply transition transition-colors hover:text-primary {} }

  &__value-container {
    @apply flex flex-nowrap {}

    &--is-multi {
      @apply flex-wrap p-1 {} }

    input {
      @apply left-0 #{!important} {} } }

  &__single-value {
    @apply m-0 whitespace-nowrap truncate {}
    color: inherit; }

  &__menu {
    @apply -ms-10 mt-0 py-2 shadow-multi border-0 overflow-hidden bg-white min-w-32 rounded-2xl {} }

  &__menu-list {
    @apply p-0 {} }

  &__clear-indicator,
  &__dropdown-indicator {
    @apply hidden #{!important} {} }

  &__placeholder {
    @apply m-0 start-2 text-gray-400 {} } }

.css-1okebmr-indicatorSeparator {
  @apply hidden #{!important} {} }

div.react-small-center-select,
button.react-small-center-select {
  &__option {
    @apply flex items-center w-full px-2 py-1.5 leading-6 cursor-pointer transition transition-colors text-sm text-gray-600 whitespace-nowrap {}

    &.-small-padding {
      @apply px-1 #{!important} {} }

    &:hover {
      @apply text-primary {} }

    &--is-focused {
      @apply bg-white text-primary {} }

    &--is-selected {
      @apply bg-tertiary-light/5 #{!important} {} } } }

span.react-small-center-select__indicator-separator {
  @apply hidden #{!important} {} }

.react-small-center-select__multi-value__label {
  max-width: 120px !important; }
