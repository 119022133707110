div[class*=-container] {
  @apply focus:border-primary {} }

div.react-select {
  $this: &;

  &__control {
    @apply border-2 border-transparent bg-transparent border-dashed min-h-12 transition transition-colors rounded shadow-none cursor-pointer text-sm flex {}
    @apply hover:border-gray-300 hover:bg-white {}

    &--is-focused {
      @apply border-primary border-solid bg-white hover:border-primary {}

      #{$this}__clear-indicator {
        @apply visible #{!important} {} } } }

  &__dropdown-indicator {
    @apply transition transition-colors hover:text-primary {} }

  &__value-container {
    @apply flex flex-nowrap {}

    &--is-multi {
      @apply flex-wrap p-1 {} }

    input {
      @apply left-0 #{!important} {} } }

  &__single-value {
    @apply m-0 whitespace-nowrap truncate {}
    color: inherit; }

  &__menu {
    @apply m-0 py-2 shadow-multi border-0 overflow-hidden bg-white min-w-48 rounded-ts-none rounded-2xl {} }

  &__menu-list {
    @apply p-0 {} }

  &__clear-indicator,
  &__dropdown-indicator {
    @apply hidden #{!important} {} }

  &__placeholder {
    @apply m-0 start-2 text-gray-400 {} } }



div.small-react-select {
  $this: &;

  &__control {
    @apply -mb-2 -mt-2 border-2 border-transparent bg-transparent border-dashed transition transition-colors rounded shadow-none cursor-pointer text-sm flex {}
    @apply hover:border-gray-300 hover:bg-white {}

    &--is-focused {
      @apply border-primary border-solid bg-white hover:border-primary {}

      #{$this}__clear-indicator {
        @apply visible #{!important} {} } } }

  &__dropdown-indicator {
    @apply transition transition-colors hover:text-primary {} }

  &__value-container {
    @apply flex flex-nowrap {}

    &--is-multi {
      @apply flex-wrap p-1 {} }

    input {
      @apply left-0 #{!important} {} } }

  &__single-value {
    @apply m-0 whitespace-nowrap truncate {}
    color: inherit; }

  &__menu {
    @apply m-0 py-2 shadow-multi border-0 overflow-hidden bg-white min-w-48 rounded-ts-none rounded-2xl {} }

  &__menu-list {
    @apply p-0 {} }

  &__clear-indicator,
  &__dropdown-indicator {
    @apply hidden #{!important} {} }

  &__placeholder {
    @apply m-0 start-2 text-gray-400 {} } }


div.react-select-grid-view {
  $this: &;

  &__control {
    @apply border-2 border-transparent bg-transparent border-dashed transition transition-colors rounded shadow-none cursor-pointer text-sm flex {}
    @apply hover:border-gray-300 hover:bg-white {}
    min-height: 2.25rem !important;

    &--is-focused {
      @apply border-primary border-solid bg-white hover:border-primary {}

      #{$this}__clear-indicator {
        @apply visible #{!important} {} } } }

  &__dropdown-indicator {
    @apply transition transition-colors hover:text-primary {} }

  &__value-container {
    @apply flex flex-nowrap {}

    &--is-multi {
      @apply flex-wrap p-1 {} }

    input {
      @apply left-0 #{!important} {} } }

  &__single-value {
    @apply m-0 whitespace-nowrap truncate {}
    color: inherit; }

  &__menu {
    @apply m-0 py-2 shadow-multi border-0 overflow-hidden bg-white min-w-48 rounded-s-none rounded-2xl z-10 {} }

  &__menu-list {
    @apply p-0 {} }

  &__clear-indicator,
  &__dropdown-indicator {
    @apply hidden #{!important} {} }

  &__placeholder {
    @apply m-0 start-2 text-gray-400 {} } }

div.custom-field-select {
  $this: &;

  &__control {
    @apply border-2 border-gray-300 bg-transparent border-solid min-h-12 transition transition-colors rounded shadow-none cursor-pointer text-sm flex {}
    @apply hover:border-primary hover:bg-white {}

    &--is-focused {
      @apply border-primary border-solid bg-white hover:border-primary {}

      #{$this}__clear-indicator {
        @apply visible bg-danger #{!important} {} } } }

  &__value-container {
    @apply flex flex-nowrap {}

    input {
      @apply left-0 #{!important} {} } }

  &__single-value {
    @apply m-0 whitespace-nowrap truncate {}
    color: inherit; }

  &__menu {
    @apply m-0 py-2 shadow-multi border-0 overflow-hidden bg-white min-w-48 rounded-s-none rounded-2xl {} }

  &__menu-list {
    @apply p-0 {} }

  &__dropdown-indicator {
    @apply text-gray-500 hover:text-primary #{!important} {} }

  &__indicator-separator {
    @apply hidden #{!important} {} }

  &__placeholder {
    @apply m-0 start-2 text-gray-400 {} } }

.css-1okebmr-indicatorSeparator {
    @apply hidden #{!important} {} }

div.react-select,
button.react-select {
  &__option {
    @apply flex items-center w-full px-2 py-1.5 leading-6 cursor-pointer transition transition-colors text-sm text-gray-600 whitespace-nowrap {}

    &.-small-padding {
      @apply px-1 #{!important} {} }

    &:hover {
      @apply text-primary {} }

    &--is-focused {
      @apply bg-white text-primary {} }

    &--is-selected {
      @apply bg-tertiary-light/5 #{!important} {} } } }

div.small-react-select,
button.small-react-select {
  &__option {
    @apply flex items-center w-full px-2 py-1.5 leading-6 cursor-pointer transition transition-colors text-sm text-gray-600 whitespace-nowrap {}

    &.-small-padding {
      @apply px-1 #{!important} {} }

    &:hover {
      @apply text-primary {} }

    &--is-focused {
      @apply bg-white text-primary {} }

    &--is-selected {
      @apply bg-tertiary-light/5 #{!important} {} } } }

span.react-select__indicator-separator {
  @apply hidden #{!important} {} }

.react-select-small {
  div.react-select__menu {
    @apply min-w-32 {} } }


.datepicker-dropdown {
  &__clear-indicator,
  &__dropdown-indicator {
    @apply visible #{!important} {} } }

.custom-field__input {
   @apply text-sm border-gray-300 hover:border-solid #{!important} {} }

.react-select__multi-value__label {
  max-width: 120px !important; }

div.react-select-grid-view__value-container--is-multi {
  max-height: 50px !important;
  overflow: auto !important; }

.react-select-grid-view__multi-value__label {
  max-width: 80px !important; }
