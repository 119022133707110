body:not([dir="rtl"]) .hyphenate {
  hyphens: auto; }

.stroke-3 path {
  stroke-width: 3px; }

.stroke-4 path {
  stroke-width: 4px; }

.autocolor {
  --perceived-lightness: calc(var(--bg-r) *.2126/255 + var(--bg-g) *.7152/255 + var(--bg-b) *.0722/255);
  --lightness-switch: max(0, min(calc(var(--perceived-lightness) *-1000 - var(--lightness-threshold) *-1000), 1));
  --lightness-threshold: .7;
  background: rgb(var(--bg-r), var(--bg-g), var(--bg-b));
  color: hsl(0, 0%, calc(var(--lightness-switch) *100%));

  &.-primary-default {
    --bg-r: 0;
    --bg-g: 178;
    --bg-b: 169; } }

.hyphenate {
  word-break: break-word; }

.min-w-fill {
  min-width: -webkit-fill-available;
  min-width: -moz-available; }
