.login-bg {
  background: {
    image: linear-gradient(to right, rgba(0, 62, 81, 0.5), rgba(0, 62, 81, 0) 200%), url("#{$image-path}/login-bg.jpg");
    position: center;
    repeat: no-repeat;
    size: cover; }
  max-height: 100vh;
  position: sticky;
  top: 0;

  [dir="rtl"] & {
    background-image: linear-gradient(to left, rgba(0, 62, 81, 0.5), rgba(0, 62, 81, 0) 200%), url("#{$image-path}/login-bg.jpg"); } }

.custom-login-bg {
  @if $custom-image-path {
    background-image: url("#{$custom-image-path}/custom-login-bg.png"); }

  [dir="rtl"] & {
    @if $custom-image-path {
      background-image: url("#{$custom-image-path}/custom-login-bg.png"); } } }

.signup-bg {
  background: {
    image: url("#{$image-path}/signup-bg-English.jpg");
    position: center;
    repeat: no-repeat;
    size: cover; }

  [dir="rtl"] & {
    background-image: url("#{$image-path}/signup-bg-Arabic.jpg"); } }
