.fc {
  --fc-border-color: theme('colors.gray.200');
  --fc-event-border-color: theme('colors.tertiary.light');
  --fc-event-bg-color: theme('colors.tertiary.light');
  --fc-now-indicator-color: theme('colors.warning'); }

.fc-day-today {
  @apply text-primary {} }

.fc-timeline-slot {
  @apply text-sm {} }

a.fc-event {
  @apply transition-colors hover:bg-tertiary {} }

.fc .fc-highlight {
  @apply bg-tertiary-light/5 {} }

.fc-timeline-event-harness:hover {
  z-index: 2; }

.fc-resource-timeline-divider {
  @apply pointer-events-none lg:pointer-events-auto {} }

#timeline-parent-div {
  pointer-events: none !important; }
