body {
  @apply text-gray-600 {}
  @apply bg-gray-200 {}
  line-height: 1.5rem; }

h1 {
  @apply font-bold text-gray-700 text-xl {}
  hyphens: auto;

  [dir="rtl"] & {
    hyphens: none; } }

h2 {
  @apply text-gray-600 text-lg {}
  hyphens: auto;

  [dir="rtl"] & {
    hyphens: none; } }

button:focus,
input:focus,
a:focus {
  outline: none; }

textarea,
[aria-labelledby*="contenteditable"] {
  hyphens: auto;

  [dir="rtl"] & {
    hyphens: none; } }

[dir="rtl"] .rtl-mirror {
  transform: scaleX(-1); }

.hero {
  background: {
    image: linear-gradient(rgba(255, 255, 255, .5), rgba(255, 255, 255, .1)), url("#{$image-path}/hero-bg.jpg");
    position: center bottom;
    repeat: no-repeat;
    size: cover; } }

.custom-hero {
  @if $custom-image-path {
    background-image: linear-gradient(rgba(255, 255, 255, .5), rgba(255, 255, 255, .1)), url("#{$custom-image-path}/custom-hero-bg.png"); } }

.diagonal-pattern {
  background: repeating-linear-gradient(-45deg, #EBEFF5, #EBEFF5 8px, #FAFBFD 5px, #FAFBFD 18px); }

@keyframes bugfinder {
  0%,
  100% {
    transform: scale(1); }
  50% {
    transform: scale(2); } }

@keyframes recolor {
  0% {
    color: red; }
  100% {
    color: magenta; } }

.localhost {
  [class*="false"],
  [class*="undefined"],
  [class*="className"],
  [class*="null"],
  [id*="false"],
  [id*="undefined"],
  [id*="className"],
  [id*="null"] {
    //animation: bugfinder infinite 1s, recolor infinite 1s alternate !important

    * {
 } } }      //animation: recolor infinite 1s alternate !important
