@import "react-datepicker/dist/react-datepicker";

div.react-datepicker {
  @apply min-w-80 border-0 rounded shadow #{!important} {}
  font-style: normal;
  line-height: 19px;

  &--open {
    @apply border-solid border-primary {} }

  &__wrapper {
    @apply border-none items-center {} }

  &__inputGroup {
    @apply p-0 {}
    direction: ltr; }

  &__calendar {
    @apply mt-0.5 {} } }


.rmdp-shadow {
  @apply rounded shadow #{!important} {} }

.clearable-input {
  position: relative;
  display: flex; }

.clearable-input > input {
  padding-right: 1.4em; }

.clearable-input:hover > [data-clear-input] {
  display: flex; }

.clearable-input > [data-clear-input] {
  display: none;
  position: absolute;
  font-weight: bold;
  font-size: 1.4em;
  padding: 0 0.2em;
  line-height: 1em;
  cursor: pointer; }

.clearable-input > input::-ms-clear {
  display: none; }

.react-datepicker__week {
  @apply flex justify-around #{!important} {} }

.react-datepicker__month-container {
  min-width: 100% !important;
  float: unset !important; }

.react-datepicker__day-names {
  @apply flex justify-around bg-white px-1 #{!important} {} }

.react-datepicker__day-name {
  font-weight: 400 !important; }

.react-datepicker__header {
  @apply bg-white text-gray-700 font-extrabold mt-0 #{!important} {}
  border-bottom: unset !important;
  padding-top: unset !important;
  background-color: #F7F9FB !important; }


.react-datepicker__header react-datepicker__header--custom {
  @apply text-primary #{!important} {} }

.react-datepicker__today-button {
  @apply bg-primary #{!important} {} }

.react-datepicker__triangle {
  display: none !important; }

.react-datepicker__day--keyboard-selected {
  border: none !important;
  background-color: transparent !important;
  color: unset !important; }

.react-datepicker__close-icon {
  top: 25%;
  left: 7px;
  width: 16px;
  height: 16px !important;
  position: absolute; }

.react-datepicker__close-icon::after {
  left: 0 !important;
  top: 0;
  margin: 0; }

.react-datepicker__day--outside-month {
  opacity: 0.2 !important; }

.picker-today-custom {
  @apply font-extrabold bg-primary/10 rounded flex items-center text-primary justify-center w-8 h-7 #{!important} {} }

.picker-selected-custom {
  @apply text-white font-bold flex items-center bg-primary justify-center w-8 h-7 #{!important} {} }

.picker-day-custom {
  @apply flex items-center justify-center w-8 h-7 #{!important} {} }

.picker-today-button {
  @apply flex justify-center items-center w-12 rounded text-sm p-1 cursor-pointer hover:text-primary #{!important} {}
  font-style: normal !important;
  font-weight: 400 !important; }

.picker-today-button:hover {
  border: 1px solid #00B2A9 !important; }

.rtlFont {
  font-family: Almarai, Lato, serif !important; }

.ltrFont {
  font-family: Lato, Almarai, serif !important; }
