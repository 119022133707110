.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 19px; }


.switch input {
  opacity: 0;
  width: 0;
  height: 0; }


.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s; }


.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 2.5px;
  bottom: 2.5px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s; }


input:checked + .slider {
  background-color: #00B2A9; }


input:focus + .slider {
  box-shadow: 0 0 1px #00B2A9; }


input:checked + .slider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px); }


/* Rounded sliders */
.slider.round {
  border-radius: 34px; }


.slider.round:before {
  border-radius: 50%; }
