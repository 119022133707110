
.draggable-item {
  display: block;
  background-color: #fff;
  outline: 0;
  border: 1px solid rgba(0, 0, 0, 0.125);
  height: 50px;
  line-height: 50px;
  width: 300px;
  margin-bottom: 2px;
  margin-top: 2px; }


.opacity-ghost {
  border: 1px dashed green; }


.hover {
  border: 1px solid blue; }

.smooth-dnd-container {
  align-items: flex-start !important;
  direction: ltr !important; }

.smooth-dnd-disable-touch-action * {
  touch-action: none !important; }

.simple-draggable-item {
  border-style: none !important;
  --tw-shadow-color: #eaeaea !important;
  cursor: move !important; }
