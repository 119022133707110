.checkbox {
  @apply absolute opacity-0 {}

  &__label {
    @apply cursor-pointer text-sm flex items-center relative {} }

  & + .checkbox__label:before {
    content: '';
    @apply relative w-6 h-6 bg-white border-2 border-gray-300 me-2 {} }

  &:checked + .checkbox__label:before {
    content: url("#{$image-path}/check.png");
    @apply bg-primary border-transparent {} }

  &:focus-visible {
    + .checkbox__label:after {
      content: '';
      @apply absolute -start-0 -top-0 w-6 h-6 rounded {} }

    + .checkbox__label:before {
      @apply bg-primary-dark border-transparent transition transition-colors {} }

    + .checkbox__label-roundedfull:before {
      @apply rounded-full {} }

    + .checkbox__label-roundednormal:before {
      @apply rounded {} } } }

.lowercase-input {
  text-transform: lowercase;

  &::placeholder {
    text-transform: none; } }

[data-title] {
  @apply relative cursor-default text-white {}

  &:nth-child(5n + 5)::before {
    @apply end-0 start-auto {} }

  &::before {
    background-color: inherit;
    color: inherit;
    content: attr(data-title);
    max-width: 250%;
    @apply rounded-sm px-1 py-0.5 absolute text-xs -top-1.5 start-0 transform opacity-0 transition pointer-events-none block truncate {} }

  &:hover {
    @apply z-10 {}

    &::before {
      @apply opacity-100 pointer-events-auto {} } } }
