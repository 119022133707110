.ProseMirror {
  @apply border-none min-h-12 w-full px-2 py-2.5 leading-6 focus:outline-none {} }

.ProseMirror,
.rte-output {
  li {
    @apply list-decimal m-4 {} }

  blockquote {
    @apply m-4 italic {} }

  p:empty {
    @apply h-6 {} } }
